import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from './settings/authConfig'
import { MsalProvider } from '@azure/msal-react'
import { InteropContextProvider } from './services/InteropContextProvider'
import AuthContextProvider from './services/AuthContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
export const msalInstance = new PublicClientApplication(msalConfig)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
