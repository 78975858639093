export const COLORS = {
  UI: {
    Brand3: '#00245D',
    PrimaryText: '#2563EB',
    PrimaryTextStrong: '#1E3A8A',
    Primary10: '#A4C5E8',
    Primary20: '#6699D2',
    Primary50: '#1F5EB7',
    Primary60: '#3B82F6',
    Primary70: '#0847BE',
    Primary80: '#1E40AF',
    White: '#FFFFFF',
    OffWhite: '#e5e5e5',
    Gray10: '#F0F2F5',
    Gray20: '#334155',
    Gray30: '#D0D7E2',
    Gray40: '#A7AFBC',
    Gray: '#475569',
    Gray50: ' #7B8698',
    Gray130: '#666666',
    Success2: '#22C373',
    Negative2: '#C72F24',
    Black: '#000000',
    Gray90: '#0F1825',
    Gray100: '#1E293B',
    LightWhite: '#EEF3F5',
    NeutralText: '#0F172A',
    NeutralDisabled: '#A7AFBC',
    NeutralTextWeak: '#475569',
    Danger: ' #BA0517',
    Transparent: 'transparent',
    DisabledText: '#A7AFBC',
  },
  Background: {
    Danger: '#BA0517',
    Success: '#2e844a',
    Warning: '#FB923C',
    NeutralMedium: '#F1F5F9',
    Primary20: '#6699D2',
    Primary90: '#000833',
    Primary: '#eff6ff',
    Neutral80: '#1E293B',
    Primary10: '#A4C5E8',
    PrimaryText: '#2563EB',
    Primary5: '#bfdbfe',
    Primary50: '#1F5EB7',
    Primary60: '#3B82F6',
    Primary70: '#0847BE',
    Primary80: '#002698',
    PrimaryBgStrongActive: '#1E40AF',
    White: '#FFFFFF',
    OffWhite: '#e5e5e5',
    Brand3: '#00245D',
    Gray10: '#F0F2F5',
    Gray20: '#E6EAF0',
    Gray30: '#D0D7E2',
    Gray40: '#A7AFBC',
    Gray50: '#cbd5e1',
    Gray60: '#596579',
    Gray70: '#374253',
    Gray80: '#F8FAFC',
    Gray90: '#A7AFBC4D',
    Gray130: '#A7AFBC1A',
    Gray100: '#1E293B',
    Gray120: '#334155',
    Green: '#42CF8C',
    Accent: '#1F5EB7',
    Gray: '#EBF2FA',
    OffWhite4: '#EBF7E6',
    OffWhite5: '#FEF1EE',
    OffWhite6: '#FFF7ED',
    OffWhite7: '#e8f0ff',
    OffWhite8: '#DBEAFE',
    Success2: '#22C373',
    Neutral: '#F8FAFC',
    NeutralStrong: '#E2E8F0',
    Black: '#000000',
    Salmon: '#fa8072',
    OffWhite1: '#f0f2f5',
    Transparent: 'transparent',
    OverlayBackground: 'rgba(100, 116, 139, 0.75)',
  },
  Border: {
    Primary5: '#bfdbfe',
    Primary10: '#A4C5E8',
    Primary20: '#6699D2',
    Primary30: '#00a9ce',
    Primary50: '#1F5EB7',
    Primary60: '#3B82F6',
    Primary70: '#0847BE',
    Primary80: '#002698',
    Primary90: '#000833',
    Primary100: '#CFF2FE',
    Primary800: '#05388E',
    PrimaryBorderStrong: '#60A5FA',
    PrimaryText: '#2563EB',
    Info100: '#D5FEFD',
    Info800: '#0F5490',
    White: '#FFFFFF',
    Brand1: '#00A9CE',
    Brand2: '#326295',
    Brand3: '#00245D',
    Gray05: '#FAFBFC',
    Gray10: '#F0F2F5',
    Gray20: '#E6EAF0',
    Gray30: '#D0D7E2',
    Gray40: '#A7AFBC',
    Gray50: '#cbd5e1',
    Gray60: '#596579',
    Gray70: '#374253',
    Gray110: '#E2E8F0',
    Gray120: '#e3ebff',
    Gray130: '#e0e0de',
    Danger: '#C72F24',
    Green: '#42CF8C',
    Accent: '#1F5EB7',
    Gray: '#EBF2FA',
    Success2: '#22C373',
    SuccessStrong: '#41B658',
    Salmon1: '#FE7765',
    Orange: '#FB923C',
    Neutral: '#F8FAFC',
    NeutralBorderWeak: '#E2E8F0',
    NeutralMedium: '#F1F5F9',
    Transparent: 'transparent',
  },
}

export const DEFAULT_COLORS = {
  Background: {
    NeutralDisabled: '#A7AFBC',
    Neutral: '#FFFFFF',
    NeutralWeak: '#F8FAFC',
    NeutralStrong: '#E2E8F0',
    NeutralMedium: '#F1F5F9',
    Primary: '#2563EB',
    PrimaryLight: '#eff6ff',
    PrimaryStrong: '#1E3A8A',
    PrimaryBorderStrong: '#60A5FA',
    PrimaryHover: '#DBEAFE',
    PrimaryActive: '#BFDBFE',
    DangerStrong: '#EA001E',
    Primary10: '#A4C5E8',
    Success: '#EBF7E6',
    OffWhite4: '#EBF7E6',
    NeutralGray: '#CBD5E1',
    GrayOpacity: '#A7AFBC4D',
  },
  UI: {
    UserDark: '#303030',
    Elevation: '#00245D',
    Neutral: '#0F172A',
    NeutralWeak: '#475569',
    NeutralInverted: '#FFFFFF',
    Primary: '#2563EB',
    PrimaryStrong: '#1E3A8A',
    Success: '#2E844A',
    NeutralDisabled: '#A7AFBC',
    PrimaryActive: '#1E40AF',
    Danger: '#BA0517',
  },
  Border: {
    Primary: '#2563EB',
    PrimaryStrong: '#60A5FA',
    PrimaryActive: '#2563EB',
    Neutral: '#CBD5E1',
    NeutralMedium: '#F1F5F9',
    NeutralHover: '#64748B',
    NeutralWeak: '#E2E8F0',
    NeutralDisabled: '#A7AFBC',
    Accent: '#1F5EB7',
  },
  Icons: {
    Primary: '#2563EB',
    PrimaryActive: '#1E3A8A',
    Neutral: '#475569',
    NeutralDisabled: '#A7AFBC',
    NeutralInverted: '#FFFFFF',
    NeutralHover: '#334155',
    Danger: '#BA0517',
    Success: '#2E844A',
  },
  BoxShadow: {
    StyledCardShadow: 'rgba(0, 36, 93, 0.3)',
    Shadow: '0px 2px 6px -2px rgba(0, 36, 93, 0.3)',
    Shadow2: 'rgba(0, 0, 0, 0.075)',
    Shadow3: 'rgba(0, 36, 93, 0.3)',
    PlusMenuBoxShadow: '0px 8px 16px -8px rgba(0, 36, 93, 0.3)',
  },
  Text: {
    UserDark: '#303030',
    Elevation: '#00245D',
    Neutral: '#0F172A',
    NeutralWeak: '#475569',
    NeutralInverted: '#FFFFFF',
    Primary: '#2563EB',
    PrimaryStrong: '#1E3A8A',
    Success: '#2E844A',
    DarkGreen: '#22C373',
    NeutralDisabled: '#A7AFBC',
    PrimaryActive: '#1E40AF',
    DodgerBlue: '#3B82F6',
    Danger: '#BA0517',
    DangerDark: '#C72F24',
    DangerDarkStrong: '#ff0000',
    Black: '#000000',
    EbonyClay: '#1E293B',
    Warning: '#C2410C',
  },
}

export const LIGHT_COLORS = {
  Background: {
    Neutral: '#FFFFFF',
    NeutralWeak: '#F8FAFC',
    Primary10: '#A4C5E8',
    NeutralStrong: '#E2E8F0',
    NeutralMedium: '#F1F5F9',
    Primary: '#2563EB',
    PrimaryLight: '#eff6ff',
    PrimaryStrong: '#1E3A8A',
    PrimaryBorderStrong: '#60A5FA',
    PrimaryHover: '#DBEAFE',
    PrimaryActive: '#BFDBFE',
    DangerStrong: '#EA001E',
    Success: '#EBF7E6',
    OffWhite4: '#EBF7E6',
    NeutralGray: '#CBD5E1',
    GrayOpacity: '#A7AFBC4D',
  },
  Text: {
    UserDark: '#303030',
    Elevation: '#00245D',
    Neutral: '#0F172A',
    NeutralWeak: '#475569',
    NeutralInverted: '#FFFFFF',
    Primary: '#2563EB',
    PrimaryStrong: '#1E3A8A',
    Success: '#2E844A',
    NeutralDisabled: '#A7AFBC',
    PrimaryActive: '#1E40AF',
    Danger: '#BA0517',
  },
  Border: {
    Primary: '#2563EB',
    PrimaryStrong: '#60A5FA',
    PrimaryActive: '#2563EB',
    Neutral: '#CBD5E1',
    NeutralMedium: '#F1F5F9',
    NeutralHover: '#64748B',
    NeutralWeak: '#E2E8F0',
    NeutralDisabled: '#A7AFBC',
    Accent: '#1F5EB7',
  },
  Icons: {
    Primary: '#2563EB',
    PrimaryActive: '#1E3A8A',
    Neutral: '#475569',
    NeutralDisabled: '#A7AFBC',
    NeutralInverted: '#FFFFFF',
    NeutralHover: '#334155',
    Danger: '#BA0517',
    Success: '#2E844A',
  },
  BoxShadow: {
    StyledCardShadow: 'rgba(0, 36, 93, 0.3)',
    Shadow: '0px 2px 6px -2px rgba(0, 36, 93, 0.3)',
    Shadow2: 'rgba(0, 0, 0, 0.075)',
    Shadow3: 'rgba(0, 0, 0, 0.075)',
    PlusMenuBoxShadow: '0px 8px 16px -8px rgba(0, 36, 93, 0.3)',
  },
}

export const DARK_COLORS = {
  Background: {
    Neutral: '#303030',
    NeutralWeak: '#424242',
    NeutralStrong: '#505357',
    NeutralMedium: '#5b5d5f',
    Primary: '#434446',
    PrimaryLight: '#eff6ff',
    PrimaryStrong: '#fff',
    Primary10: '#A4C5E8',
    PrimaryBorderStrong: '#60A5FA',
    PrimaryHover: '#DBEAFE',
    PrimaryActive: '#BFDBFE',
    DangerStrong: '#EA001E',
    Success: '#EBF7E6',
    OffWhite4: '#EBF7E6',
    NeutralGray: '#CBD5E1',
    GrayOpacity: '#A7AFBC4D',
  },
  Text: {
    UserDark: '#303030',
    Elevation: '#d4d4d4',
    Neutral: '#e7eeff',
    NeutralWeak: '#b7cae4',
    NeutralInverted: '#ffffff',
    Primary: '#fff',
    PrimaryStrong: '#fdfdfd',
    Success: '#2E844A',
    NeutralDisabled: '#A7AFBC',
    PrimaryActive: '#1E40AF',
    Danger: '#BA0517',
  },
  Border: {
    Primary: '#2563EB',
    PrimaryStrong: '#60A5FA',
    PrimaryActive: '#2563EB',
    Neutral: '#CBD5E1',
    NeutralMedium: '#F1F5F9',
    NeutralHover: '#64748B',
    NeutralWeak: '#E2E8F0',
    NeutralDisabled: '#A7AFBC',
    Accent: '#1F5EB7',
  },
  Icons: {
    Primary: '#2563EB',
    PrimaryActive: '#1E3A8A',
    Neutral: '#ebedf0',
    NeutralDisabled: '#A7AFBC',
    NeutralInverted: '#FFFFFF',
    NeutralHover: '#334155',
    Danger: '#BA0517',
    Success: '#2E844A',
  },
  BoxShadow: {
    StyledCardShadow: 'rgba(0, 36, 93, 0.3)',
    Shadow: '0px 2px 6px -2px rgba(0, 36, 93, 0.3)',
    Shadow2: 'rgba(0, 0, 0, 0.075)',
    Shadow3: 'rgba(0, 0, 0, 0.075)',
    PlusMenuBoxShadow: '0px 8px 16px -8px rgba(0, 36, 93, 0.3)',
  },
}
