import styled from 'styled-components'
import {DEFAULT_COLORS } from '../../theme/Colors'
import { TextSizes, TextWeight } from '../../theme/Sizes'
interface TypographyProps {
  children?: React.ReactNode
  isDown?: string
  stateChange?: boolean
}
const StyledHeading = styled.div`
  font-family: 'Source Sans Pro';
  font-size: ${TextSizes[10]};
  line-height: ${TextSizes[11]};
  font-weight: ${TextWeight[3]};
  color: ${DEFAULT_COLORS.UI.Neutral};
`
export const WDStyledHeading = ({ children, ...props }: TypographyProps) => {
  return <StyledHeading {...props}>{children}</StyledHeading>
}
