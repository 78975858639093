import React from 'react'

const SvgError400 = () => {
  return (
    <>
      <svg
        width="136"
        height="136"
        viewBox="0 0 136 136"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M46.6701 132.568C39.9763 130.357 33.667 127.118 27.9684 122.968L31.171 118.571C36.4137 122.389 42.2182 125.368 48.3765 127.403L46.6701 132.568Z"
          fill="#F1F5F9"
        />
        <path
          d="M25.0997 120.76C17.2245 114.356 10.8827 106.271 6.53953 97.0966C2.19638 87.9226 -0.037938 77.8926 0.000487271 67.7426L5.44045 67.7632C5.4051 77.1012 7.46067 86.3288 11.4564 94.7688C15.4521 103.209 21.2865 110.647 28.5317 116.539L25.0997 120.76Z"
          fill="#F1F5F9"
        />
        <rect
          x="9"
          y="9.00001"
          width="118.691"
          height="118.691"
          rx="59.3455"
          fill="#EFF6FF"
        />
        <rect
          x="8"
          y="26.3333"
          width="118"
          height="78.6667"
          rx="4"
          fill="#E2E8F0"
        />
        <path
          d="M8 30.3333C8 28.1242 9.79086 26.3333 12 26.3333H122C124.209 26.3333 126 28.1242 126 30.3333V37.5714H8V30.3333Z"
          fill="#CBD5E1"
        />
        <rect
          x="39.8413"
          y="78.7778"
          width="56.1905"
          height="3.74603"
          rx="1.87302"
          fill="#A7AFBC"
        />
        <rect
          x="45.4604"
          y="84.3969"
          width="46.8254"
          height="3.74603"
          rx="1.87302"
          fill="#A7AFBC"
        />
        <rect
          x="51.0796"
          y="90.0159"
          width="35.5873"
          height="3.74603"
          rx="1.87302"
          fill="#A7AFBC"
        />
        <rect
          x="11.7461"
          y="30.0794"
          width="3.74603"
          height="3.74603"
          rx="1.87302"
          fill="white"
        />
        <rect
          x="17.3652"
          y="30.0794"
          width="3.74603"
          height="3.74603"
          rx="1.87302"
          fill="white"
        />
        <rect
          x="22.9839"
          y="30.0794"
          width="3.74603"
          height="3.74603"
          rx="1.87302"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.6929 49.8965C66.4691 48.5984 68.3497 48.5984 69.1259 49.8965L81.5322 70.6438C82.3294 71.9769 81.3689 73.6702 79.8157 73.6702H55.0031C53.4498 73.6702 52.4894 71.9769 53.2866 70.6438L65.6929 49.8965ZM65.5062 57.8105C65.5062 56.7594 66.3583 55.9073 67.4094 55.9073C68.4605 55.9073 69.3126 56.7594 69.3126 57.8105V64.1544C69.3126 65.2055 68.4605 66.0576 67.4094 66.0576C66.3583 66.0576 65.5062 65.2055 65.5062 64.1544V57.8105ZM67.4094 68.5951C66.3583 68.5951 65.5062 69.4472 65.5062 70.4983C65.5062 71.5494 66.3583 72.4015 67.4094 72.4015C68.4605 72.4015 69.3126 71.5494 69.3126 70.4983C69.3126 69.4472 68.4605 68.5951 67.4094 68.5951Z"
          fill="#60A5FA"
        />
        <path
          d="M34.8911 87.0244C23.1753 75.3087 23.1753 56.3137 34.8911 44.598C46.6068 32.8822 65.6017 32.8822 77.3175 44.598C89.0332 56.3137 89.0332 75.3087 77.3175 87.0244C65.6017 98.7401 46.6068 98.7401 34.8911 87.0244Z"
          stroke="#2563EB"
          strokeWidth="8"
        />
        <mask
          id="mask0_12456_820"
          //   style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="13"
          y="22"
          width="84"
          height="84"
        >
          <path
            d="M28.1734 90.2064C13.5287 75.5617 13.5287 51.818 28.1734 37.1734C42.818 22.5287 66.5617 22.5287 81.2064 37.1734C95.851 51.818 95.851 75.5617 81.2064 90.2064C66.5617 104.851 42.818 104.851 28.1734 90.2064Z"
            stroke="#1D4ED8"
            strokeWidth="8"
          />
        </mask>
        <g mask="url(#mask0_12456_820)">
          <path
            d="M34.8911 87.0244C23.1753 75.3087 23.1753 56.3137 34.8911 44.598C46.6068 32.8822 65.6017 32.8822 77.3175 44.598C89.0332 56.3137 89.0332 75.3087 77.3175 87.0244C65.6017 98.7401 46.6068 98.7401 34.8911 87.0244Z"
            stroke="#1D4ED8"
            strokeWidth="8"
          />
        </g>
        <rect
          x="70.9531"
          y="87.7315"
          width="10"
          height="36"
          rx="5"
          transform="rotate(-45 70.9531 87.7315)"
          fill="#2563EB"
        />
        <path
          d="M97.1162 111.066C95.6761 111.317 93.9957 110.774 92.7226 109.501L74.1353 90.9135C75.6974 89.3514 79.205 90.3263 81.1046 92.226L95.551 106.672C96.8241 107.945 97.3669 109.626 97.1162 111.066Z"
          fill="#1D4ED8"
        />
      </svg>
    </>
  )
}

export default SvgError400
