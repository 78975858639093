const appConfig={
  envName: 'DEV',
  appName:'Document Scanning',
  appDomain:'https://docmanager.dev.wedbush.com',
  apiEndPoint: 'https://dev.api.wedbush.com',
  b2cDomain:'https://wedbushclientsdev.b2clogin.com/wedbushclientsdev.onmicrosoft.com/B2C_1A_SUSI_ADFED',
  b2cClientId: 'beffc06f-c344-4634-8f7a-a52d582d5246',
  b2cScope: 'https://wedbushclientsdev.onmicrosoft.com/3a0d6004-ab60-4056-b6eb-3d14aa33b105/api_access',
  b2cSecret: 'dy8Q~.WpTYH6zFggwFYGpUVu0SRcH.L0peDAa~Q',
}

export default appConfig
