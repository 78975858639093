const ENDPOINTS = {
  GET_DOCUMENT_DETAILS: '/docmanagerexperienceapi/GetDocumentDetailsList?',
  GET_ACCOUNT_DETAILS_BY_ID:
    '/docmanagerexperienceapi/{id}/GetByAccountNumberOnUserId/{mailId}',
  GET_ACCOUNT_DETAILS_BY_ID_OLD:
    '/docmanagerexperienceapi/{id}/DocumentsByAccountNumber?PageNumber={PageNumber}&PageSize={PageSize}',
  GET_ACCOUNT_DETAILS_BY_CLIENTID:
    '/docmanagerexperienceapi/{id}/DocumentsByClientId',
  GET_ACCOUNT_DETAILS_BY_ENTITY:
    '/docmanagerexperienceapi/{id}/DocumentsByEntityCode?PageNumber={PageNumber}&PageSize={PageSize}',
  GET_ACCOUNT_DETAILS_BY_OFFICE_CODE:
    '/docmanagerexperienceapi/{id}/GetByOfficeCodeOnUserId/{mailId}',
  GET_ACCOUNT_DETAILS_BY_OFFICE_CODE_OLD:
    '/docmanagerexperienceapi/{id}/DocumentsByOfficeCode?PageNumber={PageNumber}&PageSize={PageSize}',
  GET_ACCOUNT_DETAILS_BY_REP_CODE:
    '/docmanagerexperienceapi/{id}/GetByRepCodeOnUserId/{mailId}',
  GET_ACCOUNT_DETAILS_BY_REP_CODE_OLD:
    '/docmanagerexperienceapi/{id}/DocumentsByRepCode?PageNumber={PageNumber}&PageSize={PageSize}',
  GET_DROPDOWNLIST: '/docmanagerexperienceapi/LookUpMapping',
  POST_UPLOAD: '/docmanagerexperienceapi/PostDocument',
  STATUS_MAPPING: '/docmanagerexperienceapi/StatusLookUp',
  UPDATE_ACCOUNT: '/docmanagerexperienceapi/UpdateDocuments',
  ACCOUNT_VALIDATE: '/docmanagerexperienceapi/{id}/AccountValidation',
  DOWNLOAD_FILE: '/docmanagerexperienceapi/Download?id={id}',
  DELETE_ID: '/docmanagerexperienceapi/Delete/{id}',
  USERS_MAPPING: '/docmanagerexperienceapi/UserInfo/{id}',
  USER_ACCESS_MAPPING: '/docmanagerexperienceapi/GetUserAccess/{id}',
  USER_ENTITLEMENT_MAPPING: '/docmanagerexperienceapi/GetUserEntitlement/{id}',
  GET_CLIENT_LIST_BY_NAME: '/clientapi/Party/GetClientListByName',
  GET_ACCOUNTS_BY_NUMBER: '/clientapi/Party/GetClientListByAccountNumber',
  SUBMIT_FILE: '/docmanagerexperienceapi/SubmitFile',
  GET_STATEMENTS: '/docmanagerexperienceapi/GetStatements',
  DOCUMENTS_BY_DRAFT_RECORDS:
    '/docmanagerexperienceapi/DocumentsByDraftRecords',
  DOWNLOAD_STATEMENTS:
    '/docmanagerexperienceapi/ConvertPDFByFilePath?filePath=',
  GET_REPACCESS_INFO_BY_USERNAME:
    '/advisormasterapi/VisibilityCheck/GetRepAccessLevelsByUserName',
  //Entitlement API
  GET_USER_ENTITLEMENT:
    '/advisormasterapi/commonauthorization/GetUserEntitlements',
}

export default ENDPOINTS
