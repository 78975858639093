declare global {
  interface Window {
    iobrowser?: {
      platformVersion?: string
    }
    iodesktop?: boolean
  }
}

const useIsInteropEnv = () => {
  const isIOBrowser =
    !!(window as any)?.iobrowser &&
    Object.prototype.hasOwnProperty.call(window.iobrowser, 'platformVersion')
  const isIODesktop = !!(window as any)?.iodesktop
  const isInteropEnv = isIOBrowser || isIODesktop
  return { isInteropEnv, isIOBrowser, isIODesktop }
}

export default useIsInteropEnv
