import React from 'react'

const SvgError500 = () => {
  return (
    <>
      <svg
        width="136"
        height="136"
        viewBox="0 0 136 136"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M46.6701 132.568C39.9763 130.357 33.667 127.118 27.9684 122.968L31.171 118.571C36.4137 122.389 42.2182 125.368 48.3765 127.403L46.6701 132.568Z"
          fill="#F1F5F9"
        />
        <path
          d="M25.0997 120.76C17.2245 114.356 10.8827 106.271 6.53953 97.0966C2.19638 87.9226 -0.037938 77.8926 0.000487271 67.7426L5.44045 67.7632C5.4051 77.1012 7.46067 86.3288 11.4564 94.7688C15.4521 103.209 21.2865 110.647 28.5317 116.539L25.0997 120.76Z"
          fill="#F1F5F9"
        />
        <rect
          x="9"
          y="9.00001"
          width="118.691"
          height="118.691"
          rx="59.3455"
          fill="#DBEAFE"
        />
        <rect
          x="8.5"
          y="27.5"
          width="119"
          height="79.6667"
          rx="4.5"
          fill="white"
          stroke="#CBD5E1"
        />
        <path
          d="M9 32C9 29.7909 10.7909 28 13 28H69V107H13C10.7909 107 9 105.209 9 103V32Z"
          fill="#E2E8F0"
        />
        <path
          d="M9 32C9 29.7909 10.7909 28 13 28H123C125.209 28 127 29.7909 127 32V39.2381H9V32Z"
          fill="#CBD5E1"
        />
        <rect
          x="12.7461"
          y="31.746"
          width="3.74603"
          height="3.74603"
          rx="1.87302"
          fill="white"
        />
        <rect
          x="18.3652"
          y="31.746"
          width="3.74603"
          height="3.74603"
          rx="1.87302"
          fill="white"
        />
        <rect
          x="23.9839"
          y="31.746"
          width="3.74603"
          height="3.74603"
          rx="1.87302"
          fill="white"
        />
        <path
          d="M69 59L79.3923 65V77L69 83L58.6077 77V65L69 59Z"
          fill="#2563EB"
        />
        <circle cx="69" cy="71" r="4" fill="white" />
        <path
          d="M8 51H26.25C28.0449 51 29.5 52.4551 29.5 54.25V54.25C29.5 56.0449 30.9551 57.5 32.75 57.5H51.5C53.7091 57.5 55.5 59.2909 55.5 61.5V88C55.5 90.2091 57.2909 92 59.5 92H69"
          stroke="#CBD5E1"
        />
        <path
          d="M31 101H24.5C22.2909 101 20.5 99.2091 20.5 97V86C20.5 83.7909 18.7091 82 16.5 82H8.5"
          stroke="#CBD5E1"
        />
        <path
          d="M59 68H49.5C47.2909 68 45.5 66.2091 45.5 64V58.5"
          stroke="#2563EB"
        />
        <circle cx="45" cy="57" r="1.5" fill="#2563EB" stroke="#2563EB" />
        <path d="M31 107V94" stroke="#2563EB" />
        <path
          d="M8 64L23 64C25.2091 64 27 65.7909 27 68L27 68.5L27 73"
          stroke="#2563EB"
        />
        <circle cx="31" cy="93" r="2" fill="#2563EB" />
        <circle
          cx="27"
          cy="73"
          r="2"
          transform="rotate(90 27 73)"
          fill="#2563EB"
        />
        <circle cx="40" cy="87" r="2" fill="#CBD5E1" />
        <circle cx="63" cy="49" r="2" fill="#CBD5E1" />
        <path
          d="M55.5 77.5H44C41.7909 77.5 40 79.2909 40 81.5V107.5"
          stroke="#CBD5E1"
        />
        <path
          d="M49.5 39V45C49.5 47.2091 51.2909 49 53.5 49H69"
          stroke="#CBD5E1"
        />
        <path
          d="M69 86H91.5C92.3284 86 93 86.6716 93 87.5C93 88.3284 92.3284 89 91.5 89H69V86Z"
          fill="#E2E8F0"
        />
        <path
          d="M69 91H82.5C83.3284 91 84 91.6716 84 92.5C84 93.3284 83.3284 94 82.5 94H69V91Z"
          fill="#E2E8F0"
        />
        <path
          d="M69 96H75.5C76.3284 96 77 96.6716 77 97.5C77 98.3284 76.3284 99 75.5 99H69V96Z"
          fill="#E2E8F0"
        />
      </svg>
    </>
  )
}

export default SvgError500
