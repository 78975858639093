import IODesktop from '@interopio/desktop'
import IOBrowser from '@interopio/browser'
import {
    IOConnectInitSettings,
    IOConnectProvider,
    IOConnectContext
} from '@interopio/react-hooks'
import IOWorkspaces from '@interopio/workspaces-api'
import { useContext } from 'react'
import useIsInteropEnv from './useIsInteropEnv'
//import Loader from '../components/loader/Loader'

interface Props {
    children: React.ReactNode
}

export const InteropContextProvider = ({ children }: Props) => {

    const { isInteropEnv } = useIsInteropEnv()

    const io = useContext(IOConnectContext)
    const settings: IOConnectInitSettings = {
        desktop: {
            factory: IODesktop,
            config: {
                channels: true,
                appManager: 'full',
                layouts: 'full',
                libraries: [IOWorkspaces],
            },
        },
        browser: {
            factory: IOBrowser,
            config: {
                libraries: [IOWorkspaces],
            },
        },
    }

    return (
        <>
            {isInteropEnv ?
                <IOConnectProvider
                    fallback={
                        <>Loading...</>
                    }
                    settings={settings}
                >
                    {children}
                </IOConnectProvider> : <>{children}</>
            }
        </>
    )
}
export default InteropContextProvider