import { createContext, ReactNode, useMemo, useState } from 'react'

export interface ToastContextInterface {
  toastOpen: boolean | null
  toastMessage: string
  setToast: (isOpen: boolean | null, message: string) => void
}

const ToastContext = createContext<ToastContextInterface>(
  {} as ToastContextInterface
)

interface ContextProps {
  children?: ReactNode
}

export const ToastContextProvider = ({ children }: ContextProps) => {
  const [toastOpen, setToastOpen] = useState<boolean | null>(null)
  const [toastMessage, setToastMessage] = useState<string>('')

  const setToast = (isOpen: boolean | null, message: string) => {
    setToastOpen(isOpen)
    setToastMessage(message)
  }

  const value = useMemo(
    () => ({
      toastOpen,
      toastMessage,
      setToast,
    }),
    [toastOpen, toastMessage]
  )

  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
}

export default ToastContext
