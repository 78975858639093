import { useContext, useEffect, useState } from 'react'
import { AxiosRequestConfig } from 'axios'
import Loader from '../../assets/lottiefiles/loader.json'
import Lottie from 'lottie-react'
import { LottieWrapper, SpinnerWrapper } from './styles'
import axiosProtectedInstance from '../../services/http/axiosService'
import {
  AuthContext,
  AuthCtxProviderInterface,
} from '../../services/AuthContext'
import { useAccount, useMsal } from '@azure/msal-react'

let failedApiCall = true
const AxiosProtectedInterceptor = () => {
  const { accounts } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [isLoading, setLoading] = useState(false)
  const authCtx = useContext(AuthContext) as AuthCtxProviderInterface

  useEffect(() => {
    const requestIntercept = axiosProtectedInstance.interceptors.request.use(
      (config: AxiosRequestConfig): any => {
        if (!config.headers?.Authorization) {
          config.headers = {}
          config.headers.Authorization = `Bearer ${authCtx.accessToken}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    const responseIntercept = axiosProtectedInstance.interceptors.response.use(
      (response) => {
        setLoading(false)
        return response
      },
      async (error) => {
        setLoading(false)
        const prevRequest = error?.config
        const expDate = account?.idTokenClaims?.exp
            ? new Date(account?.idTokenClaims?.exp * 1000)
            : new Date(),
          now = new Date()
        const forceRefreshPage = expDate < now
        if (forceRefreshPage) {
          failedApiCall = true
        }
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true
          if (failedApiCall && authCtx.b2cRefreshToken) {
            failedApiCall = false
            authCtx?.b2cRefreshToken()
            prevRequest.headers.Authorization = `Bearer ${authCtx?.accessToken}`
            return axiosProtectedInstance(prevRequest)
          } else {
            prevRequest.headers.Authorization = `Bearer ${authCtx?.accessToken}`
            return axiosProtectedInstance(prevRequest)
          }
        }
        return Promise.reject(error)
      }
    )
    return () => {
      axiosProtectedInstance.interceptors.request.eject(requestIntercept)
      axiosProtectedInstance.interceptors.response.eject(responseIntercept)
    }
  }, [account, account?.idTokenClaims?.exp, authCtx, authCtx?.accessToken])

  return (
    <>
      {isLoading && (
        <SpinnerWrapper>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
        </SpinnerWrapper>
      )}
    </>
  )
}

export default AxiosProtectedInterceptor
