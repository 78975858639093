import ErrorComposite from '../../composites/errorComposite/ErrorComposite'
import { RootContainer } from './styles'

interface IErrorProps {
  error: object | undefined | any
}

const ErrorPage = ({ error }: IErrorProps) => {

  return (
    <>
      <RootContainer>
        <ErrorComposite
          errorCode={error?.error?.response?.status}
          errorMessage={error?.error?.message}
        />
      </RootContainer>
    </>
  )
}

export default ErrorPage
