import styled from 'styled-components'
import { AnchorTagProps } from './IAnchorTag'

export const StyledAnchor = styled.a<AnchorTagProps>`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  color: ${(props: AnchorTagProps) => props.color};
  pointer-events: ${(props: AnchorTagProps) => props.pointer};
  cursor: ${(props: AnchorTagProps) => props.cursor};
  line-height: ${(props: AnchorTagProps) => props.lineHeight};
  text-decoration: ${(props: AnchorTagProps) => props.textDecoration};
  &:active {
    color: ${(props: AnchorTagProps) => props.color};
    text-decoration: ${(props: AnchorTagProps) => props.textDecoration};
  }
  &:visited {
    color: ${(props: AnchorTagProps) => props.color};
    text-decoration: ${(props: AnchorTagProps) => props.textDecoration};
  }
  &:disabled {
    color: ${(props: AnchorTagProps) => props.color};
  }
  &:hover {
    color: ${(props: AnchorTagProps) => props.color};
    text-decoration: ${(props: AnchorTagProps) => props.textDecoration};
  }
`
