import { createContext, ReactNode, useMemo, useState } from 'react'
interface ThemeContextProps {
  isDarkMode: boolean,
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>
}
export const ThemeContext = createContext<ThemeContextProps>({} as ThemeContextProps)

interface ContextProps {
  children?: ReactNode
}

export const ThemeContextProvider = ({ children }: ContextProps) => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false)

  const values = useMemo(
    () => ({
      isDarkMode, setIsDarkMode
    }),
    [isDarkMode, setIsDarkMode]
  )

  return (
    <ThemeContext.Provider value={values}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContextProvider
