import { StyledButton } from './styles'
import { FC } from 'react'
import Image from '../image/Image'
import { ButtonProps } from './IButton'

/**
 * creates customizable button
 */
const Button: FC<ButtonProps> = ({
  type,
  bgColor,
  color,
  children,
  borderColor,
  padding,
  borderRadius,
  prefixedIcon,
  suffixedIcon,
  disabled,
  onClick,
  hover,
  hasBorder = true,
  iconWidth,
  iconHeight,
  borderLessBgColor,
}: ButtonProps) => {
  return (
    <StyledButton
      type={type}
      className="hover-button"
      bgColor={bgColor}
      color={color}
      borderColor={borderColor}
      padding={padding}
      borderRadius={borderRadius}
      onClick={onClick}
      disabled={disabled}
      hover={hover}
      hasBorder={hasBorder}
      borderLessBgColor={borderLessBgColor}
    >
      {prefixedIcon && (
        <Image
          image={prefixedIcon}
          alt={'Icon'}
          width={iconWidth}
          height={iconHeight}
        />
      )}
      {children}
      {suffixedIcon && (
        <Image
          image={suffixedIcon}
          alt={'Icon'}
          width={iconWidth}
          height={iconHeight}
        />
      )}
    </StyledButton>
  )
}

export default Button
