import styled from 'styled-components'

export const RootContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 20%;
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
`
